"use client"

import * as React from "react"
import * as LabelPrimitive from "@radix-ui/react-label"
import {cva, type VariantProps} from "class-variance-authority"

import {cn} from "@/lib/utils"

const labelVariants = cva(
    "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
    {
        variants: {
            variant: {
                heading1: "text-[80px] font-normal font-['Recoleta']",
                heading2: "text-[60px] font-normal font-['Recoleta']",
                subHeading1: "text-[36px] font-light font-['Recoleta']",
                subHeading2: "text-[18px] font-light font-['Recoleta']",
                body1: "text-[22px] font-light font-roboto",
                body2: "text-[16px] font-light font-roboto",
                body3: "text-[14px] font-light font-roboto",
            }
        },
        defaultVariants: {
            variant: "body1"
        }
    }
)

const Label = React.forwardRef<
    React.ElementRef<typeof LabelPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants>
>(({className, variant, ...props}, ref) => (
    <LabelPrimitive.Root
        ref={ref}
        className={cn(labelVariants({variant}), className)}
        {...props}
    />
))
Label.displayName = LabelPrimitive.Root.displayName

export {Label}
